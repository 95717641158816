import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloProvider } from "@apollo/client";

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "https://www.thinktempo.com",
        scope: "openid profile email",
      },
    });

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const resetToken = onError(({ response, networkError }) => {
    if (networkError?.statusCode === 401 || networkError?.statusCode === 403) {
      loginWithRedirect();
    }
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([resetToken, authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
