import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const makeFileUploadRequest = async (file, questionSetName) => {
  const { getAccessTokenSilently } = useAuth0();
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: "https://www.thinktempo.com", //TODO: put in env var
      scope: "openid profile email user",
    },
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("questionSetName", questionSetName);
  try {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/import/questions`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: token?.accessToken
            ? `Bearer ${token.accessToken}`
            : "",
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};
