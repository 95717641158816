export const USER_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  department: "",
  startDate: "",
  userIsActive: false,
  tags: [],
};

export const USER_ACTION_TYPES = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  PHONE: "phone",
  DEPARTMENT: "department",
  START_DATE: "startDate",
  USER_IS_ACTIVE: "userIsActive",
  TAGS: "tags",
  ADD_TAG: "addTag",
  REMOVE_TAG: "removeTag",
  INITIALIZE_STATE: "initializeState",
};

export const USER_STATE_REGEX = {};

export const UserRoles = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  NON_PRIVILEGED: "NON_PRIVILEGED",
};

export const isValidRole = (role) => {
  return Object.values(UserRoles).includes(role);
};

export const hasRequiredRole = (userRole, requiredRole) => {
  const roleHierarchy = {
    [UserRoles.SUPER_ADMIN]: 3,
    [UserRoles.ADMIN]: 2,
    [UserRoles.NON_PRIVILEGED]: 1,
  };

  return roleHierarchy[userRole] >= roleHierarchy[requiredRole];
};

export const isAdminOrHigher = (userRole) => {
  return (
    hasRequiredRole(userRole, UserRoles.ADMIN) ||
    hasRequiredRole(userRole, UserRoles.SUPER_ADMIN)
  );
};
