import { action, thunk } from "easy-peasy";
import { UserRoles, isValidRole } from "../../constants/users";

const userModel = {
  // State
  role: null,
  email: null,
  roleCheckIsLoading: false,
  error: null,
  companyOverrideId: 1,

  // Actions
  setRole: action((state, payload) => {
    if (isValidRole(payload)) {
      console.log("setting role", payload);
      state.role = payload;
    } else {
      console.error(`Invalid role: ${payload}`);
      state.role = UserRoles.NON_PRIVILEGED;
    }
  }),
  setRoleCheckIsLoading: action((state, payload) => {
    state.roleCheckIsLoading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setEmail: action((state, payload) => {
    state.email = payload;
  }),
  setCompanyOverrideId: action((state, payload) => {
    state.companyOverrideId = payload;
  }),

  // Thunks
  fetchUserRole: thunk(async (actions, payload, { getState }) => {
    actions.setRoleCheckIsLoading(true);
    // set email in the store
    actions.setEmail(payload.email);

    try {
      // TODO: Make this endpoint
      //   const response = await fetch(
      //     `${process.env.REACT_APP_API_URL}/user-role`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${payload.token}`,
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({
      //         email: payload.email,
      //       }),
      //     }
      //   );
      //   const data = await response.json();
      //   actions.setRole(data.role);
      actions.setRole(UserRoles.SUPER_ADMIN); //TODO: Remove this
    } catch (error) {
      actions.setError(error.message);
    } finally {
      actions.setRoleCheckIsLoading(false);
    }
  }),
};

export default userModel;
