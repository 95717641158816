import { gql } from '@apollo/client';

export const GET_USERS = gql`
  {
    users {
      id
      key
      firstName
      lastName
      startDate
      email
    }
  }
`;