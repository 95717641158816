import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { StoreProvider } from "easy-peasy";

import App from "./App";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StoreProvider store={store}>
    <Auth0Provider
      domain="dev-k37yvrifgftgabzb.us.auth0.com"
      clientId="x4ZYhjtSE8qdA8mPzzEcRxRCjJsH67kn"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://www.thinktempo.com",
        scope: "openid profile email user",
      }}
    >
      <App />
    </Auth0Provider>
  </StoreProvider>
);
