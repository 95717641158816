import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreState, useStoreActions } from "easy-peasy";

import { Dashboard } from "../pages/Dashboard";
import { ProfilesPage } from "../pages/ProfilesPage";
import { ProfilePage } from "../pages/ProfilePage";
import { QuestionsPage } from "../pages/QuestionsPage";
import { UsersPage } from "../pages/UsersPage";
import { AddQuestionPage } from "../pages/ProfilePage";
import { QuizPage } from "../pages/QuizPage";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import PageContainer from "../components/PageContainer/PageContainer";
import { isAdminOrHigher } from "../constants/users";

const AppRoutes = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header />
      <SideNav />
      <PageContainer>
        <Routes>
          <Route element={<div>Unauthorized</div>} path="/unauthorized" />
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Navigate to="/profiles" />
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
            path="/dashboard"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProfilesPage />
              </ProtectedRoute>
            }
            path="/profiles"
          />
          <Route
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
            path="/profile"
          />
          <Route element={<AddQuestionPage />} path="/add-question" />
          <Route
            element={
              <ProtectedRoute>
                <QuestionsPage />
              </ProtectedRoute>
            }
            path="/questions"
          />
          <Route
            element={
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            }
            path="/users"
          />
          <Route element={<QuizPage />} path="/start" />
        </Routes>
      </PageContainer>
    </Router>
  );
};

const ProtectedRoute = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: auth0IsLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const userRole = useStoreState((state) => state.user.role);
  const fetchUserRole = useStoreActions(
    (actions) => actions.user.fetchUserRole
  );
  const roleCheckIsLoading = useStoreState(
    (state) => state.user.roleCheckIsLoading
  );

  React.useEffect(() => {
    const getUserRole = async () => {
      if (isAuthenticated && user?.email) {
        try {
          const token = await getAccessTokenSilently();
          await fetchUserRole({
            token,
            email: user.email,
          });
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    };

    getUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently, fetchUserRole]);

  if (auth0IsLoading || roleCheckIsLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  if (userRole && !isAdminOrHigher(userRole)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};
export default AppRoutes;
