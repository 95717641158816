import * as React from "react";
import { Select, MenuItem } from "@mui/material";
import { useStoreState, useStoreActions } from "easy-peasy";

export const CompanySelect = () => {
  const { companyOverrideId } = useStoreState((state) => state.user);
  const setCompanyOverrideId = useStoreActions(
    (actions) => actions.user.setCompanyOverrideId
  );

  // TODO: get all companies from backend

  return (
    <Select
      autoWidth
      value={companyOverrideId}
      size="small"
      style={{ marginRight: "10px" }}
      onChange={(e) => setCompanyOverrideId(e.target.value)}
      MenuProps={{
        sx: { zIndex: 4000 }, // Higher than header's 3000
      }}
    >
      <MenuItem value="1">Company 1</MenuItem>
      <MenuItem value="2">Company 2</MenuItem>
      <MenuItem value="3">Company 3</MenuItem>
    </Select>
  );
};
