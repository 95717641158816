import React from "react";
import { useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { HeaderContainer } from "./Header.styles";
import AtlasLogo from "../../images/atlas-icon.png";
import { LogoutButton } from "../logout";
import { CompanySelect } from "../CompanySelect";
import { UserRoles } from "../../constants/users";

const Header = () => {
  const location = useLocation();
  const userRole = useStoreState((state) => state.user.role);

  const isSuperAdmin = userRole === UserRoles.SUPER_ADMIN;

  return (
    <HeaderContainer>
      <img src={AtlasLogo} alt="logo" height="70px" width="70px" />
      {isSuperAdmin && location.pathname !== "/start" ? (
        <div>
          <CompanySelect />
          <LogoutButton />
        </div>
      ) : location.pathname !== "/start" ? (
        <LogoutButton />
      ) : null}
    </HeaderContainer>
  );
};

export default Header;
