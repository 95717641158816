import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./styles";
import AppRoutes from "./routes/Routes";
import AuthorizedApolloProvider from "./helpers/apolloClient";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

loadDevMessages();
loadErrorMessages();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthorizedApolloProvider>
        <Fragment>
          <AppRoutes />
          <GlobalStyle />
        </Fragment>
      </AuthorizedApolloProvider>
    </ThemeProvider>
  );
};

export default App;
