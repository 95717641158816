import { gql } from '@apollo/client';

export const GET_COMPANY_DATA = gql`
  query CompanyQuizData($filters: CompanyDataInput!) {
    getCompanyData(filters: $filters) {
      quizData {
        averageScore
        numberOfQuizzes
        quizzesCompleted
        quizzesStarted
        date
      },
      questionSetData {
        questionSetName
        incorrectPercentage
        correctPercentage
        totalAnswered
        answeredCorrectly
        answeredIncorrectly
      },
    }
  }
`;
