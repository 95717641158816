import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  ${(props) => {
    const { theme } = props;
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      padding: 0px 30px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${theme.zIndex.levelThree};
      box-shadow: ${theme.shadow};
      background-color: ${theme.colors.black_shade_0};
    `;
  }}
`;
