export const timeZones = [
  {
    "label": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(value-08:00) Pacific Standard Time (US & Canada)",
    "value": "America/Los_Angeles",
  },
  {
    "label": "US Mountain Standard Time (Arizona)",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(value-07:00) Arizona",
    "value": "America/Phoenix",

  },
  {
    "label": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(value-07:00) Mountain Time (US & Canada)",
    "value": "America/Denver",

  },
  {
    "label": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(value-06:00) Central Time (US & Canada)",
    "value": "America/Chicago",

  },
  {
    "label": "Eastern Standard Time",
    "abbr": "EST",
    "offset": -5,
    "isdst": false,
    "text": "(value-05:00) Eastern Time (US & Canada)",
    "value": "America/New_York",

  },
]

