import React, { useState } from 'react';
import { PROFILE_ACTION_TYPES } from '../../constants';
import { removeTypename } from '../../helpers/removeTypename';
import { 
  GET_PROFILE, 
  UPDATE_PROFILE, 
  ADD_USERS_TO_PROFILE, 
  REMOVE_USER_FROM_PROFILE, 
  ADD_QUESTION_SETS_TO_PROFILE,
  REMOVE_QUESTION_SET_FROM_PROFILE
} from './profileQueries';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  Tabs,
  Tab,
  Box,
  Typography
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import BallotIcon from '@mui/icons-material/Ballot';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
// Components
import ProfileGeneral from './ProfileGeneral'
import ProfileQuestion from './ProfileQuestion'
import ProfileUser from './ProfileUser'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProfilePage = (props) => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [userAddedSuccess, setUserAddedSuccess] = useState(false)
  const [selectedProfileId, setSelectedProfileId] = useState(location?.state?.selectedProfileId);
  // const  { selectedProfileId } = props.location.state
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [updateProfile, { data: updateProfileData }] = useMutation(UPDATE_PROFILE)
  const handleUpdateProfile = async (updatedProfile) => {
    dayjs.extend(utc)
    if(updatedProfile) {
      let time = ''
      if(updatedProfile.time) {
        time = dayjs(updatedProfile.time).hour().toString()
        console.log('CONVERTED TIME --', time)
      }
      console.log('SAVING --', updatedProfile)

      await updateProfile({
        variables: {
          profileId: updatedProfile.id,
          name: updatedProfile.name,
          numQuestions: updatedProfile.numQuestions,
          tempo: {
            ...removeTypename(updatedProfile.tempo),
            time,
            timeZone: updatedProfile.timeZone
          }
        }
      })

      setUpdateSuccess(true)
      return
    } 
  }

  const [addUsersToProfile] = useMutation(ADD_USERS_TO_PROFILE)
  const handleAddUsers = async (userIds) => {
    console.log('ADDING USERS --', userIds)
    if(!selectedProfileId || userIds?.length === 0) return null
    
    await addUsersToProfile({
      variables: {
        profileId: selectedProfileId,
        userIds: userIds
      },
      refetchQueries: [
        GET_PROFILE, // DocumentNode object parsed with gql
        'GetProfile' // Query name
      ],
    })

    setUserAddedSuccess(true)
  }

  const [removeUserFromProfile] = useMutation(REMOVE_USER_FROM_PROFILE)
  const handleRemoveUser = async (userId) => {
    console.log('REMOVING USER --', userId)
    if(!selectedProfileId || !userId) return null
    
    await removeUserFromProfile({
      variables: {
        profileId: selectedProfileId,
        userId
      },
      refetchQueries: [
        GET_PROFILE, // DocumentNode object parsed with gql
        'GetProfile' // Query name
      ],
    })

    setUserAddedSuccess(true)
  }

  
  const { loading, data, error } = useQuery(GET_PROFILE, { variables: { profileId: selectedProfileId }})
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :{error}</p>;
  const profile = updateProfileData?.updateProfile || data.profile
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h5'>Profile - {profile?.name}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="lab API tabs example">
          <Tab icon={<SettingsIcon />} iconPosition="start" label='General' {...a11yProps(0)} />
          <Tab icon={<GroupIcon />} iconPosition="start" label='Users' {...a11yProps(1)} />
          <Tab icon={<BallotIcon />} iconPosition="start" label='Questions' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProfileGeneral profile={profile} updateProfile={handleUpdateProfile} updateSuccess={updateSuccess} setUpdateSuccess={setUpdateSuccess}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileUser users={profile?.users} addUsersToProfile={handleAddUsers} userAddedSuccess={userAddedSuccess} removeUserFromProfile={handleRemoveUser} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProfileQuestion profileId={selectedProfileId}  />
      </TabPanel>
    </Box>
  );
};

export default ProfilePage;
