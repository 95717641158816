import React, { useEffect } from "react";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import SettingsIcon from "@mui/icons-material/Settings";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";

const drawerWidth = 200;

const SideNav = () => {
  const [signedIn, setSignedIn] = React.useState(false);
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      setSignedIn(true);
    }
  }, [isAuthenticated]);

  if (window?.location?.pathname?.includes("start")) {
    return null;
  }

  const isActivePage = (page) => {
    return window.location.pathname.includes(page);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#F1F1F2",
          paddingTop: "30px",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      {signedIn ? (
        <List>
          {["Profiles", "Questions", "Users", "Metrics"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={
                isActivePage(text.toLowerCase())
                  ? {
                      backgroundColor: "#d8d8d9",
                      borderLeft: "5px solid #FF7E00",
                    }
                  : {}
              }
            >
              <ListItemButton
                onClick={() => {
                  if (text === "Profiles") {
                    window.location.href = "/profiles";
                  } else if (text === "Questions") {
                    window.location.href = "/questions";
                  } else if (text === "Users") {
                    window.location.href = "/users";
                  } else if (text === "Metrics") {
                    window.location.href = "/dashboard";
                  }
                }}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <SettingsIcon />
                  ) : index === 1 ? (
                    <QuestionMarkIcon />
                  ) : index === 2 ? (
                    <PeopleIcon />
                  ) : (
                    <BarChartIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Drawer>
  );
};

export default SideNav;
